import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Top-Rated UI Development Company`,
    content:
      "From intuitive designs to user-friendly experiences, Octet’s UI UX design and development services create visually attractive interfaces that capture and convert.",
  };

  const commonContent6 = {
    title: "UI Development Services to Grow Retention",
    para: "Octet brings your digital concepts to life by creating visually appealing interfaces. As a top UI development agency, we use design concepts, programming languages, and industry expertise to create custom and functional UI solutions.",
    img: ["what-service-image-uidev.webp"],
    imageTitle: "Web Development Tools",
    imageAlt: "Custom Web Development Services",
    content: [
      {
        title: "Custom Web App Development",
        text: "We aim to provide customized, effective, and interactive digital UI UX solutions that meet your requirements.",
      },
      {
        title: "Web Development",
        text: "We create dynamic and engaging platforms that captivate your audience and enhance their session time.",
      },
      {
        title: "Ecommerce Development",
        text: "Our UI UX designers guide your firm in building a solid online presence, which promotes growth in the digital economy.",
      },
      {
        title: "Front End Development",
        text: "We strive to provide compelling user experience development that resonates with your target audience.",
      },
      {
        title: "Back-end Web Development",
        text: "We create websites that have reliable functioning and address the complexities of data processing, security, and scalability.",
      },
      {
        title: "Support and Maintenance",
        text: "We provide support to ensure that your digital assets continue to work correctly and evolve in response to changing requirements.",
      },
    ],
  };

  const commonContent = {
    title: "Transforming Industries with Custom UI Solutions",
    para: "In today's changing digital landscape, custom UI UX development services catalyze profitable change in industries seeking to increase user experience and engagement.",
    img: ["who-needs-uidev.webp"],
    imageTitle: "UI Development Code",
    imageAlt: "custom web development agency",
    content: [
      {
        title: "SaaS Companies",
        text: "Companies strive to streamline complex product functionalities to boost user experience development and customer satisfaction.",
      },
      {
        title: "Product Companies",
        text: "Companies that focus on increasing the usability of their offerings and providing straightforward navigation to prevent churn.",
      },
      {
        title: "E-commerce companies",
        text: "Companies that strive to provide a seamless experience to customers to boost customer engagement and drive business success.",
      },
      {
        title: "Software Companies",
        text: "Companies who wish to differentiate their product in the market turn to UI development services to improve the usability of their software solutions.",
      },
    ],
  };

  const commonContent2 = {
    title: "Partner with UI Development Agency for Impactful Interfaces",
    para: "As a leading UI UX app development agency, we unlock business potential by designing a user-friendly interface that improves user experience and leads to long-term success.",
    img: ["what-are-benefits-uidev.webp"],
    imageTitle: "UI Development Benefits",
    imageAlt: "web application development firm",
    content: [
      {
        title: "Tailored Solutions",
        text: "Our UI UX developers employ their knowledge of design principles to cooperate with businesses to create bespoke user interfaces.",
      },
      {
        title: "Seamless Integration",
        text: "With our UI Development services, you can leverage our technical skills to connect front-end interfaces with back-ends, resulting in a seamless user experience.",
      },
      {
        title: "Cross Browser Compatibility",
        text: "UWe provide custom UI Development Services and use best practices for coding and testing interfaces to ensure they work across all web browsers.",
      },
      {
        title: "Enhanced User Experience",
        text: "Our intelligent UI solutions build interfaces that are easy to navigate, intuitive, and visually appealing, resulting in a better user experience.",
      },
      {
        title: "Security and Data Protection",
        text: "Our UI Development services protect data by using encrypted protocols and following industry standards for data security.",
      },
    ],
  };
  const commonContent3 = {
    title: "Octet: Leading UI Development Agency in India",
    linkText: ``,
    para: `Octet is a well-recognized UI development company. We use best industrial practices and cutting-edge technology to provide UI UX development services, create outstanding user experience development, and exceed our clients' expectations.`,
    img: ["why-opt-for-uidev.webp"],
    imageTitle: "UI Development Process",
    imageAlt: "ui development company",
    content: [
      {
        title: "Technical Excellence",
        text: "We have a team of highly skilled UI UX developers with extensive technical knowledge who are dedicated to providing seamless and scalable solutions for optimal performance.",
      },
      {
        title: "Industry Expertise",
        text: "Our UI development agency has extensive industry knowledge, which allows them to create customized interfaces that are most relevant to your company.",
      },
      {
        title: "Assured Quality",
        text: "To deliver high-quality UI solutions, we employ a thorough quality assurance approach that tests each interface's performance, compatibility, functionality, and other factors.",
      },
      {
        title: "Flexible Approach",
        text: "We are adaptable in our approach and recognize that each client has distinct needs. Our UI UX developers work closely with clients to meet their requirements.",
      },
    ],
  };
  const commonContent4 = {
    title: "Octet’s Approach to UI UX Development Services",
    para: "We combine creativity, technological competence, and user-centered design principles to generate business success in the digital landscape.",
    img: ["how-we-conduct-uidev.webp"],
    imageTitle: "UI UX Development & Design",
    imageAlt: "web development consulting",
    content: [
      {
        title: "1. Briefing",
        text: "The designer informs the UI UX developer on the team of the business objectives, project specifics, time frame, and client expectations.",
      },
      {
        title: "3. Testing",
        text: "Once the coding is complete, the UI developer performs thorough quality assurance checks to verify that no defects are left uncorrected.",
      },
      {
        title: "2. Development",
        text: "Based on their experience, the UI developer chooses the best framework and begins coding.",
      },
      {
        title: "4. Support",
        text: "Our product engineering team finally transfers over to the customer, guiding their team through the product's features and providing ongoing support.",
      },
    ],
  };
  const commonContent5 = {
    title: "Get More with UI UX Development Services",
    para: "Investing in UI UX design and development services ensures an improved user experience through user-friendly, visually appealing interfaces that create long-term business growth.",
    content: [
      {
        title: "30% Higher Conversion Rate*",
        text: "Companies that attempt to enhance user experience development and shorten user journeys by improving interfaces achieve 30% greater conversion rates.",
      },
      {
        title: "15% to 50% Profit Margin*",
        text: "Companies that build user-friendly interfaces gain more customers and increase revenue, with a profit margin of 15% to 50%.",
      },
      {
        title: "Errors reduced by 50%",
        text: "Companies that focus on enhancing usability can reduce customer irritation, attrition rates, and errors by up to 50%.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them.",
  };
  const cta = {
    title: "Make Every Digital Experience Fascinating",
  };
  const cta2 = {
    title: "Let’s Turn Your Ideas into Reality",
  };
  const cta3 = {
    title: "Develop Success for your Digital Presence",
  };
  const cta4 = {
    title: "Enhance UX With our UI Development Services",
  };
  const cta5 = {
    title: "Get Simplified with UI Solutions",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "Ship Delight",
      desc: "An E-Commerce Logistic Website",
      Industry: "Logistics",
      firstAlt: "ui ux development company",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "Web Development Company",
      secondTitle: "NDR Status",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp"],
    },
    {
      title: "Nirvana",
      desc: "A Financial Solutions Platform",
      Industry: "FinTech",
      firstAlt: "Custom Web Development",
      firstTitle: "Orders List",
      secondAlt: "Web Development Services",
      secondTitle: "Order Status",
      WhatWeDid: [
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/nirvana/",
      images: ["nirvana-project.webp", "nirvana-project-2.webp"],
    },
  ];

  const Questions = [
    "What languages do you use for Web Development?",
    "How do you ensure that Web Development aligns with project requirements?",
    "Do you provide ongoing support for custom Web Development projects?",
    "Can you upgrade the design using the same library?",
    "Can your developers coordinate with our in-house team?",
    "Can you use custom libraries?",
  ];
  const clientsTitle = "Trusted by Clients Worldwide";
  const faqDes = `As a prominent UI development company, you may have further questions about Custom UI solutions and UI UX design and development services. This will answer most of your questions; however, please <a href="/contact-us/" target="_blank" title="contact us">contact us</a> if you have any additional questions. `;
  const faqData = [
    {
      para: [
        `Our UI Development company creates fantastic user experience development using a variety of computer languages.`,
        `Our UI UX developers work with languages like HTML, CSS, and JavaScript, the foundation for front-end development.`,
        `To improve the functionality and interactivity of our UI development projects, we stay up with frameworks and libraries like <a target="_blank" rel="noreferrer" href="/reactjs-development/">React</a>, <a target="_blank" rel="noreferrer" href="/angularjs-development/">Angular UI</a>, and <a target="_blank" rel="noreferrer" href="/vuejs-development/">Vue.js</a>.`,
        `We also offer support for your <a target="_blank" rel="noreferrer" href="/wordpress-development/">WordPress Development</a> needs.`,
      ],
    },
    {
      para: [
        `We employ the following critical stages to guarantee our UI solutions match your project's requirements-`,
      ],
      list: [
        `<span><h4 class="inline">Identify the Project Requirements</h4></span> - We begin by studying and reviewing your project to acquire information about your target audience, project goals, desired features, specific UI requirements, and so on.`,
        `<span><h4 class="inline">Conduct User Research and Analysis</h4></span> -  Various procedures, such as surveys, interviews, usability testing, and so on, give us insights into user preferences. Understanding the user's point of view allows us to deliver UI UX development solutions that meet their needs.`,
        `<span><h4 class="inline">Collaborate with Stakeholders</h4></span> -We work with various stakeholders to meet the needs of the UI Development services, which allows us to guarantee that we are meeting everyone's expectations.`,
        `<span><h4 class="inline">Create UI Design Prototypes</h4></span> -  We then develop UI design prototypes using wire-framing and other tools. These prototypes serve as visual representations of the user interface, and we continue to iterate based on stakeholder feedback.`,
        `<span><h4 class="inline">Document and Report</h4></span> - We maintain a document outlining UI Development guidelines, design decisions, and project needs. Finally, after the final iteration is complete, we disseminate it to the stakeholders. `,
      ],
    },
    {
      para: [
        `As a leading <a target="_blank" rel="noreferrer" href="/">UI UX Development Company</a>, we recognize the value of ongoing support for your bespoke web development projects.`,
        `We are devoted to developing long-term relationships with our clients and providing comprehensive assistance during the project lifetime and post-project handover.`,
        `We ensure the success of your UI Development projects by providing aid with troubleshooting, feature additions, and other support.`,
      ],
    },
    {
      para: [
        `Yes, we may change and improve the design of your user interface using the same library.`,
        `Regardless of your framework or library, our professional UI UX developers use their knowledge and experience to apply design improvements.`,
        `We use your existing library to assure compatibility and consistency while also improving your interface's appearance and user experience.`,
      ],
    },
    {
      para: [
        `Certainly! We recognize the importance of seamless collaboration and effective communication to ensure a smooth workflow and outstanding project outputs.`,
        `As a result, our experienced developers can efficiently work with your in-house development team to achieve the finest results for your UI Development projects.`,
      ],
    },
    {
      para: [
        `Yes! As a top-rated UI UX solutions company, we can incorporate custom libraries into our projects.`,
        `We use pre-built components and design patterns supported by bespoke libraries to improve the user experience and overall design of your digital products.`,
        `Our UI UX developers evaluate the viability of current libraries and, as needed, decide whether to customize or create new libraries for each project.`
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Which languages do you use for UI development?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our UI Development company creates fantastic user experience development using a variety of computer languages.
            
            Our UI UX developers work with languages like HTML, CSS, and JavaScript, the foundation for front-end development. 
            
            To improve the functionality and interactivity of our UI development projects, we stay up with frameworks and libraries like React, Angular UI, and Vue.js.
            
            We also offer support for your WordPress development needs."
                }
              },{
                "@type": "Question",
                "name": "How do you ensure that your UI Development Services meets project requirements?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We employ the following critical stages to guarantee our UI solutions match your project's requirements:
            Identify the Project Requirements:
            We begin by studying and reviewing your project to acquire information about your target audience, project goals, desired features, specific UI requirements, and so on.
            Conduct User Research and Analysis:
            Various procedures, such as surveys, interviews, usability testing, and so on, give us insights into user preferences. Understanding the user's point of view allows us to deliver UI UX development solutions that meet their needs.
            Collaborate with Stakeholders:
            We work with various stakeholders to meet the needs of the UI Development services, which allows us to guarantee that we are meeting everyone's expectations.
            Create UI Design prototypes:
            We then develop UI design prototypes using wire-framing and other tools. These prototypes serve as visual representations of the user interface, and we continue to iterate based on stakeholder feedback.
            Document and Report:
            We maintain a document outlining UI Development guidelines, design decisions, and project needs. Finally, after the final iteration is complete, we disseminate it to the stakeholders."
                }
              },{
                "@type": "Question",
                "name": "Do you offer ongoing support for custom UI development projects?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "As a leading UI UX app development agency, we recognize the value of ongoing support for your bespoke web development projects.
            
            We are devoted to developing long-term relationships with our clients and providing comprehensive assistance during the project lifetime and post-project handover.
            
            We ensure the success of your UI Development projects by providing aid with troubleshooting, feature additions, and other support."
                }
              },{
                "@type": "Question",
                "name": "Can your UI development agency update the design with the same library?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we may change and improve the design of your user interface using the same library.
            
            Regardless of your framework or library, our professional UI UX developers use their knowledge and experience to apply design improvements.
            
            We use your existing library to assure compatibility and consistency while also improving your interface's appearance and user experience."
                }
              },{
                "@type": "Question",
                "name": "Can your UI UX developers work with our in-house team?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Certainly! We recognize the importance of seamless collaboration and effective communication to ensure a smooth workflow and outstanding project outputs.
            
            As a result, our experienced developers can efficiently work with your in-house development team to achieve the finest results for your UI Development projects."
                }
              },{
                "@type": "Question",
                "name": "Does your UX development agency use custom libraries?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes! As a top-rated UI UX solutions company, we can incorporate custom libraries into our projects.
            
            We use pre-built components and design patterns supported by bespoke libraries to improve the user experience and overall design of your digital products.
            
            Our UI UX developers evaluate the viability of current libraries and, as needed, decide whether to customize or create new libraries for each project."
                }
              }]
            }            
        `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode="grey" data={cta4} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode="grey" data={cta5} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                Crafted Impactful UI Solutions for Global Companies
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials title={'Read Our Clients Take on Us'} />
          <Cta data={cta2} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="UI Development Services | UI Development Company"
    description="Discover professional excellence with our UI Development Company in India. We build pixel-perfect designs to ensure an outstanding user experience."
  />
);
